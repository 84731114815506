<template>
  <div class="popup_wrap" style="width:550px">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.SETT010T010.076') }}</h1>
      <div class="content_box">
        <!-- content_box -->
        <div class="clearfix mb8">
          <span class="tit_label">{{ $t('msg.SETT010T010.077') }}</span>
          <select v-model.trim="params.recptEmlAddr" class="wid200" @change="emlAddrChange">
            <option value="">{{ $t('msg.CSBK100.062') }}</option>
            <option value="D">{{ $t('msg.SETT010T010.206') }}</option> <!-- 직접입력. -->
            <option v-for="(item, idx) in emailList" :key="idx" :value="item.emlAddr">{{ item.emlAddr }}</option>
          </select>
          <input type="text" class="wid200" :disabled="params.recptEmlAddr !== 'D'" v-model="userEmlAddr">
        </div>
        <p class="txt_desc asterisk">{{ $t('msg.SETT010T010.078') }}</p> <!-- email이 없을 경우에는 발급담당자와 확인 바랍니다. -->
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button blue lg" @click="sendTaxEmail()">{{ $t('msg.SETT010T010.079') }}</a> <!-- 재전송 -->
        <a class="button gray lg" @click="$emit('close')">{{ $t('msg.SETT010T010.080') }}</a> <!-- 닫기 -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'

export default {
  name: 'CalcIssueEmailTaxPop',
  components: {
    CalcIssue
  },
  props: {
    detail: {
      type: Object,
        default: function () {
        return {
          rcvRno: '',
          acshRno: '',
          blNo: '',
          invNo: ''
        }
      }
    },
    isShowPop: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      idx: 0,
      total: 0,
      params: {
        blNo: '',
        apvYn: '',
        acshIssDt: '',
        acshRno: '',
        rcvRno: '',
        cstSeq: '',
        recptEmlAddr: '',
        cnclYn: '',
        cstNo: '',
        eiCatCd: '',
        invNo: '',
        orgRecptEmlAddr: ''
      },
      userEmlAddr: '',
      snreStsCd: '',
      emailList: []
    }
  },
  watch: {
    isShowPop () {
      this.showData()
      //console.log(this.isShowPop)
    }
  },
  created: function () {
    //window.functionOutsideVue(this)
    //console.log('created')
  },
  mounted: function () {
    this.showData()
  },
  methods: {
    async showData () {
      if (this.isShowPop === true) {
        //this.params.blNo = this.detail.blNo
        //this.params.acNo = 'KMT00121110001'
        await this.selectPayment()
        this.snreStsCd = this.detail.snreStsCd
        if (this.snreStsCd === '10') await this.getFirstTaxInvoiceEmailAddr(this.detail.acshRno)
        console.log(this.detail)
      }
    },
    async selectPayment () {
      await CalcIssue.getEmailList()
        .then(response => {
          const resp = response.data
          this.emailList = resp
          console.log(resp)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getFirstTaxInvoiceEmailAddr (acshRno) {
      await CalcIssue.getFirstTaxInvoiceEmailAddr(acshRno)
        .then(response => {
          let isEmailContains = false
          this.emailList.forEach((emailAddr) => {
            if (emailAddr.emlAddr === response.data) {
              isEmailContains = true
            }
          })
          if (!isEmailContains) {
            this.emailList.unshift({ emlAddr: response.data })
          }

          console.log(this.emailList)
        })
        .catch(err => {
          console.log(err)
        })
    },
    sendTaxEmail () {
      if (this.params.recptEmlAddr === '' || this.userEmlAddr === '') {
          this.openAlert('Please Input Email Address.')
      } else {
        var usrCfm = this.detail.usrCfm
        var apvYn = this.detail.apvYn
        var rcvCargNm = this.detail.rcvCargNm
        var rcvCargTel = this.detail.rcvCargTel
        var snreStsCd = this.detail.snreStsCd

        //직접 입력 이메일 형식 체크
        if (!this.$ekmtcCommon.checkEmail(this.userEmlAddr)) {
          this.openAlert(this.$t('msg.SETT010T010.205')) // 이메일 형식을 확인해 주세요.
          this.userEmlAddr.focus()
          return
        }

        if (rcvCargTel === '--') {
          rcvCargTel = '051-461-2800'
        }
        if (apvYn !== 'Y') {
          this.openAlert(this.$t('msg.SETT010T010.097')) // 수정세금계산서 미승인건은 전송이 불가합니다.
          return
        }

        //if (usrCfm === 'Y') {
          //this.openAlert(this.$t('msg.SETT010T010.097') + ' (' + rcvCargNm + ' ' + rcvCargTel + ') ' + this.$t('msg.SETT010T010.099')) //  화주가 승인한 건은 발급담당자 ('날짜') 확인 바랍니다.
          //return;
        //}
        //this.params.recptEmlAddr = 'kimkc_metanet@ekmtc.com' //선택된 메일 주소 입력
        const now = moment()
        const curDt = now.add(-30, 'd').format('YYYYMMDD')

        this.params.acshIssDt = this.detail.acshIssDt === undefined ? curDt : this.detail.acshIssDt
        this.params.acshRno = this.detail.acshRno === undefined ? ' ' : this.detail.acshRno
        this.params.rcvRno = this.detail.rcvRno === undefined ? '' : this.detail.rcvRno
        this.params.cstSeq = this.detail.cstSeq === undefined ? ' ' : this.detail.cstSeq
        this.params.cnclYn = this.detail.cnclYn === undefined ? ' ' : this.detail.cnclYn
        this.params.cstNo = this.detail.cstNo === undefined ? ' ' : this.detail.cstNo
        this.params.recptEmlAddr = this.userEmlAddr === undefined ? '' : this.userEmlAddr
        this.params.invNo = this.detail.invNo === undefined ? '' : this.detail.invNo
        this.params.orgRecptEmlAddr = this.detail.recptEmlAddr === undefined ? '' : this.detail.recptEmlAddr

        console.log(this.detail)
        console.log(this.params)

        CalcIssue.sendEmail(this.params)
        .then(res => {
            console.log(res)

            let data = res.data

            /* if (data.issDtYn !== 'N') {
              let date = data.issDtYn.substring(0, 4) + '-' + data.issDtYn.substring(4, 6) + '-' + data.issDtYn.substring(6)

              this.openAlert(this.$t('msg.SETT010T010.100') + ' (' + date + ') ' + this.$t('msg.SETT010T010.101')) //  전자세금계산서 마감일자 (' + date + ') 이후는 전송이 불가합니다.
              return
            } */

            if (data.edi === '10') {
              this.openAlert(this.$t('msg.SETT060G010.048')) // 전송되었습니다.
              this.$emit('close')
            }
        })
      }
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    closeFun () {
      this.$Simplert.close()
    },
    emlAddrChange () {
      if (this.params.recptEmlAddr === 'D') {
        this.userEmlAddr = ''
      } else {
        this.userEmlAddr = this.params.recptEmlAddr
      }
    }
  }
}

</script>

<style scoped>
a.no-uline:hover{
  text-decoration:underline
}
</style>
