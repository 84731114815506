var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "550px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SETT010T010.076"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "clearfix mb8" }, [
            _c("span", { staticClass: "tit_label" }, [
              _vm._v(_vm._s(_vm.$t("msg.SETT010T010.077"))),
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.params.recptEmlAddr,
                    expression: "params.recptEmlAddr",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "wid200",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.params,
                        "recptEmlAddr",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.emlAddrChange,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                ]),
                _c("option", { attrs: { value: "D" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.206"))),
                ]),
                _vm._v(" "),
                _vm._l(_vm.emailList, function (item, idx) {
                  return _c(
                    "option",
                    { key: idx, domProps: { value: item.emlAddr } },
                    [_vm._v(_vm._s(item.emlAddr))]
                  )
                }),
              ],
              2
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userEmlAddr,
                  expression: "userEmlAddr",
                },
              ],
              staticClass: "wid200",
              attrs: {
                type: "text",
                disabled: _vm.params.recptEmlAddr !== "D",
              },
              domProps: { value: _vm.userEmlAddr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.userEmlAddr = $event.target.value
                },
              },
            }),
          ]),
          _c("p", { staticClass: "txt_desc asterisk" }, [
            _vm._v(_vm._s(_vm.$t("msg.SETT010T010.078"))),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  return _vm.sendTaxEmail()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.079")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.080")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }